import { Link } from 'gatsby';
import React, { useState } from 'react';
import {
  Button,
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility
} from 'semantic-ui-react';
import styled from 'styled-components';

import HomepageHeading from './HomepageHeading';

interface Props {
  course?: boolean;
  heading: string;
  subheading: string;
  children?: any;
}

const MobileSidebar = styled(({ innerAs, ...rest }) => (
  <Sidebar as={innerAs} {...rest} />
))`
  display: none;

  @media screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
    display: block;
  }
`;

const MobileSegment = styled(Segment)`
  display: none;

  @media screen and (max-width: ${Responsive.onlyMobile.maxWidth}px) {
    display: block;
  }
`;

const FixedMenu = styled(Menu)`
  display: none !important;

  &.fixed {
    display: flex !important;
  }

  @media screen and (min-width: ${Responsive.onlyTablet.minWidth}px) {
    display: flex !important;
  }
`;

const MenuButton = styled(Button)`
  margin-left: 10px !important;

  &:first-child {
    margin-left: 0 !important;
  }
`;

const TopBar = styled(Segment)`
  padding: 0 !important;

  @media screen and (min-width: ${Responsive.onlyTablet.minWidth}px) {
    padding: 1em 0em !important;
  }
`;

export default ({ course, heading, subheading, children }: Props) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [fixed, setFixed] = useState(false);

  return (
    <>
      <MobileSidebar
        innerAs={Menu}
        animation="push"
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
        className="mobile"
      >
        <Menu.Item as={Link} to="/" active>
          Home
        </Menu.Item>
        <Menu.Item as={Link} to="/contact" active>
          Hire Us
        </Menu.Item>
      </MobileSidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <MobileSegment inverted textAlign="center" vertical>
          <Container>
            <Menu inverted pointing secondary size="large">
              <Menu.Item onClick={() => setSidebarOpened(true)}>
                <Icon name="sidebar" />
              </Menu.Item>
              <Menu.Item position="right">
                <MenuButton as={Link} to="/course">
                  {course ? 'Table of Contents' : 'Launch Course'}
                </MenuButton>
              </Menu.Item>
            </Menu>
          </Container>
        </MobileSegment>

        <Visibility
          once={false}
          onBottomPassed={() => setFixed(true)}
          onBottomPassedReverse={() => setFixed(false)}
        >
          <TopBar inverted textAlign="center" vertical>
            <FixedMenu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <Menu.Item as={Link} to="/" activeClassName="active">
                  Home
                </Menu.Item>
                <Menu.Item as={Link} to="/contact" activeClassName="active">
                  Hire Us
                </Menu.Item>
                <Menu.Item position="right">
                  <MenuButton
                    as={Link}
                    to="/course"
                    className={[
                      'ui',
                      'button',
                      !fixed && 'inverted',
                      !!fixed && 'primary'
                    ]
                      .filter(Boolean)
                      .join(' ')}
                  >
                    {course ? 'Table of Contents' : 'Launch Course'}
                  </MenuButton>
                </Menu.Item>
              </Container>
            </FixedMenu>
            {!!heading && heading.length > 0 ? (
              <HomepageHeading heading={heading} subheading={subheading} />
            ) : null}
          </TopBar>
        </Visibility>
        {children}
      </Sidebar.Pusher>
    </>
  );
};
